import { Link } from 'gatsby';
import React from 'react';
import { Layout } from '../components/Layout/Layout';
import { Breadcrumb, Divider, Typography } from 'antd';
import './privacy-policy.less';
import { ArrowRightOutlined } from '@ant-design/icons';
import { ROUTES } from '../constants/routes';

const { Title, Text, Paragraph } = Typography;

const Chapter = () => {
  return (
    <Layout>
      <div className="page-wrapper">
        <Breadcrumb separator={<ArrowRightOutlined />}>
          <Breadcrumb.Item href={ROUTES.HOME}>Strona główna</Breadcrumb.Item>
          <Breadcrumb.Item href={ROUTES.PRIVACY_POLICY}>
            Polityka prywatności
          </Breadcrumb.Item>
        </Breadcrumb>
        <Divider></Divider>
        <Title>Polityka Prywatności</Title>
        <Paragraph>
          Ochrona prywatności Użytkowników jest dla Jana Kowalskiego szczególnie
          ważna. Z tego względu Użytkownicy Serwisu{' '}
          <Link to="/">www.lushhairlublin.pl</Link> mają zagwarantowane wysokie
          standardy ochrony prywatności. Jan Kowalski jako administrator danych
          dba o bezpieczeństwo udostępnionych przez Użytkowników danych.
        </Paragraph>
        <Paragraph>
          Celem Administratora jest również należyte informowanie Użytkowników o
          prawach i obowiązkach związanych z przetwarzaniem danych osobowych,
          szczególnie wobec treści przepisów o ochronie danych osobowych
          określonych w rozporządzeniu Parlamentu Europejskiego i Rady (UE)
          2016/679 z dnia 27.04.2016r. w sprawie ochrony osób fizycznych w
          związku z przetwarzaniem danych osobowych i w sprawie swobodnego
          przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako
          „RODO”). Dlatego też, dbając o ochronę prywatności Użytkowników
          Serwisu, w niniejszym dokumencie Administrator informuje o podstawach
          prawnych przetwarzania danych osobowych przekazanych przez
          Użytkowników w związku z korzystaniem przez nich z Serwisu{' '}
          <Link to="/">www.lushhairlublin.pl</Link> (dalej jako „Serwis”),
          sposobach zbierania, przetwarzania i ochrony danych osobowych, a także
          o prawach Użytkowników.
        </Paragraph>
        <Paragraph>
          Użytkownikiem jest każda osoba fizyczna, której dane dotyczą,
          korzystająca z serwisu internetowego{' '}
          <Link to="/">www.lushhairlublin.pl </Link>
          lub usług elektronicznych dostępnych za pośrednictwem Serwisu.
        </Paragraph>
        <Paragraph>
          Administratorem danych osobowych udostępnionych przez Użytkownika w
          Serwisie <Link to="/">www.lushhairlublin.pl </Link>
          jest Jan Kowalczyk, Mińska 11, 20-000 Lublin, NIP 0000000000, (dalej
          jako „Administrator”).
        </Paragraph>
        <section>
          <Title level={4}>I. ZGODA UŻYTKOWNIKA</Title>
          <Paragraph>
            Korzystanie z Serwisu <Link to="/">www.lushhairlublin.pl </Link>{' '}
            przez Użytkownika oznacza, że Użytkownik akceptuje, że Administrator
            gromadzi oraz wykorzystuje dane osobowe zgodnie z niniejszą Polityką
            Prywatności.
          </Paragraph>
          <Paragraph>
            Dane osobowe Użytkownika Serwisu są przetwarzane przez
            Administratora w oparciu o jego zgodę, zaś w niektórych przypadkach
            opisanych w niniejszym dokumencie, w ramach prawnie uzasadnionego
            interesu Administratora. Użytkownik ma prawo w dowolnym momencie
            wycofać wyrażoną wcześniej zgodę. Wycofanie zgody nie wpływa na
            zgodność z prawem przetwarzania, którego dokonano na podstawie zgody
            przed jej wycofaniem.
          </Paragraph>
          <Paragraph>
            W przypadku, gdy nastąpi zmiana niniejszej Polityki Prywatności, a
            Użytkownik nadal będzie korzystał z Serwisu, to poczytuje się to za
            wyrażenie zgody na aktualne warunki Polityki Prywatności.
          </Paragraph>
        </section>
        <section>
          <Title level={4}>
            II. DANE OSOBOWE PRZETWARZANE PRZEZ ADMINISTRATORA
          </Title>
          <Paragraph>
            <Text className={'span'}>
              1. Sposób pozyskiwania danych osobowych{' '}
            </Text>
            Administrator pozyskuje dane osobowe bezpośrednio od Użytkownika za
            pośrednictwem Serwisu, poprzez wypełnienie przez Użytkownika
            formularza kontaktowego dostępnego w Serwisie i przesłanie za jego
            pośrednictwem wiadomości do Administratora.
          </Paragraph>
          <Paragraph>
            Podanie danych osobowych zawartych w formularzu kontaktowym jest
            dobrowolne<br></br>
            <Text className={'span'}>
              2. Rodzaje przetwarzanych danych osobowych{' '}
            </Text>
            Administrator gromadzi za pośrednictwem Serwisu następujące dane
            osobowe dotyczące Użytkownika:
            <ul>
              <li>a) Imię i nazwisko;</li>
              <li>b) Adres e-mail;</li>
              <li>c) Numer telefonu;</li>
              <li>d) Treść wiadomości;</li>
              <li>e) Temat wiadomości;</li>
            </ul>
          </Paragraph>
        </section>
        <section>
          <Title level={4}>III. CELE PRZETWARZANIA DANYCH OSOBOWYCH</Title>
          <Paragraph>
            Sposób przetwarzania przez Administratora danych dotyczących
            Użytkownika zależy od sposobu korzystania przez Użytkownika z
            Serwisu i dostępnych w nim funkcjonalności. Administrator przetwarza
            dane osobowe Użytkownika w następujących celach:
          </Paragraph>
          <Paragraph>
            <Text className={'span'}>1. Komunikacji z Użytkownikiem. </Text>
            Administrator wykorzystuje dane osobowe Użytkownika w celu
            komunikacji z nim w spersonalizowany sposób. Komunikowane
            Użytkownikowi informacje dotyczą oferowanych produktów lub usług,
            bezpieczeństwa danych osobowych, aktualizacji sieci, przypomnień,
            ale również sugerowanych ofert Administratora lub jego partnerów.
            Komunikacja z Użytkownikiem dotyczy także obsługi Użytkownika. Dane
            osobowe wykorzystywane są w celu pomocy Użytkownikowi, rozwiązaniu
            problemów technicznych oraz odpowiedzi na jego skargi lub reklamacji
          </Paragraph>
          <Paragraph>
            <Text className={'span'}>
              2. Przedstawiania Użytkownikowi ofert handlowych drogą
              elektroniczną.{' '}
            </Text>
            Celem korzystania z danych osobowych Użytkownika udostępnionych
            przez niego za pośrednictwem formularza kontaktowego dostępnego w
            Serwisie jest komunikacja marketingowa prowadzona przez
            Administratora w ramach prowadzonej działalności, w szczególności
            przedstawianie ofert handlowych Użytkownikowi drogą elektroniczną.
          </Paragraph>
          <Paragraph>
            <Text className={'span'}>
              3. Umożliwienia przesyłania przez Użytkownika komentarzy lub
              opinii.{' '}
            </Text>
            Administrator wykorzystuje dane osobowe Użytkownika w celu
            umożliwienia mu komentowania/opiniowania działalności, usług lub
            produktów Administratora lub podmiotów z nim współpracujących.
            Administrator może przetwarzać dane osobowe Użytkownika podane w
            formularzu kontaktowym również w następujących celach:
            <ul>
              <li>
                1. W celu zawarcia i realizacji ewentualnej umowy pomiędzy
                Użytkownikiem a Administratorem oraz obsługi Użytkownika jako
                klienta Administratora zgodnie z art. 6 ust. 1 lit. b) RODO;
              </li>
              <li>
                2. W celu prowadzenia rozliczeń finansowych z Użytkownikiem
                będącym klientem Administratora tytułem realizacji ewentualnej
                umowy zawartej między stronami, a także ewentualnego dochodzenia
                roszczeń od Użytkownika będącego klientem w ramach prawnie
                uzasadnionego interesu Administratora zgodnie z art. 6 ust. 1
                lit. f) RODO oraz spełnienia obowiązków prawnych Administratora
                wobec organów podatkowych na podstawie odrębnych przepisów
                zgodnie z art. 6 ust. 1 lit. c) RODO;
              </li>
              <li>
                3. W celu realizacji działań marketingowych Administratora w
                ramach prawnie uzasadnionego interesu Administratora w
                rozumieniu art. 6 ust. 1 lit. f) RODO, a także zgodnie z
                oświadczeniami woli dotyczącymi komunikacji marketingowej
                złożonymi wobec Administratora. Zgody udzielone w zakresie
                komunikacji marketingowej (np. na przesyłanie informacji
                handlowych drogą elektroniczną lub kontakt telefoniczny w celach
                marketingu bezpośredniego) mogą być wycofane w dowolnym czasie,
                bez wpływu na zgodność z prawem przetwarzania, którego dokonano
                na podstawie zgody przed jej cofnięciem;
              </li>
              <li>
                4. W celu realizacji obowiązków prawnych Administratora wobec
                Użytkownika określonych w RODO, w rozumieniu art. 6 ust. 1 lit.
                c) RODO.
              </li>
            </ul>
          </Paragraph>
        </section>
        <section>
          <Title level={4}>IV. UDOSTĘPNIANIE DANYCH OSOBOWYCH</Title>
          <Paragraph>
            Dane osobowe Użytkownika nie są przekazywane przez Administratora
            podmiotom trzecim.
          </Paragraph>
        </section>
        <section>
          <Title level={4}>V. PRAWA UŻYTKOWNIKA</Title>
          <Paragraph>
            <Text className={'span'}>1. Prawa Użytkownika </Text>
            Użytkownik na każdym etapie przetwarzania jego danych zapewniony ma
            szereg uprawnień pozwalających mu uzyskać dostęp do swoich danych,
            weryfikację prawidłowości przetwarzania danych, ich korektę, jak
            również ma prawo zgłoszenia sprzeciwu wobec ich przetwarzania, może
            żądać usunięcia danych, ograniczenia przetwarzania lub przeniesienia
            danych.
            <Paragraph>
              W przypadku chęci skorzystania przez Użytkownika z przysługujących
              mu praw jako podmiotu danych osobowych, może on się skontaktować z
              Administratorem za pomocą następujących danych kontaktowych:{' '}
              <Text className={'span'}>
                Jan Kowalczyk, Mińska 11, 20-000 Lublin,
              </Text>
              <br></br>
              <a href="mailto:lolekbolek@gmail.com" className="mail">
                lolekbolek@gmail.com
              </a>
            </Paragraph>
            <Text className={'span'}>
              2. Prawo wniesienia skargi do organu nadzorczego{' '}
            </Text>
            Użytkownik, którego dane osobowe są przetwarzane przez
            Administratora ma prawo wnieść skargę do organu nadzoru właściwego w
            sprawach ochrony danych osobowych (Prezesa Urzędu Ochrony Danych
            Osobowych).
          </Paragraph>
        </section>
        <section>
          <Title level={4}>VI. PLIKI COOKIES</Title>
          <Paragraph>
            1. Administrator informuje, iż podczas korzystania z Serwisu w
            urządzeniu końcowym Użytkownika zapisywane są krótkie informacje
            tekstowe zwane „cookies”. Pliki „cookies” zawierają takie dane
            informatyczne jak: adres IP dotyczący Użytkownika, nazwa strony
            internetowej, z której pochodzą, czas przechowywania ich na
            urządzeniu końcowym Użytkownika, zapis parametrów i statystyki oraz
            unikalny numer. Pliki „cookies” są kierowane do serwera Serwisu za
            pośrednictwem przeglądarki internetowej zainstalowanej w urządzeniu
            końcowym Użytkownika. Pliki „cookies” wykorzystywane są w Serwisie w
            celu:
          </Paragraph>
          <Paragraph>
            <ul>
              <li>
                a. utrzymania technicznej poprawności i ciągłości sesji pomiędzy
                serwerem Serwisu, a urządzeniem końcowym Użytkownika;
              </li>
              <li>
                b. optymalizacji korzystania przez Użytkownika ze stron
                internetowych Serwisu i dostosowania sposobu ich wyświetlania na
                urządzeniu końcowym Użytkownika;
              </li>
              <li>c. zapewnienia bezpieczeństwa korzystania z Serwisu;</li>
              <li>
                d. zbierania statystyk odwiedzin stron Serwisu wspierających
                ulepszanie ich struktury i zawartości;
              </li>
              <li>
                e. wyświetlania na urządzeniu końcowym Użytkownika treści
                reklamowych optymalnie dostosowanych do jego preferencji.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            W ramach Serwisu stosowane są dwa rodzaje plików „cookies”:
            „sesyjne” oraz „stałe”. „Sesyjne” pliki „cookies” są plikami
            ulegającymi automatycznemu usunięciu z urządzenia końcowego
            Użytkownika Serwisu po jego wylogowaniu z Serwisu lub po opuszczeniu
            przez niego stron internetowych Serwisu lub po wyłączeniu
            przeglądarki internetowej. „Stałe” pliki „cookies” przechowywane są
            w urządzeniu końcowym Użytkownika przez czas określony w parametrach
            plików „cookies” lub do czasu ich usunięcia przez Użytkownika.
            „Stałe” pliki „cookies” instalowane są w urządzeniu końcowym
            Użytkownika wyłącznie za jego zgodą.
          </Paragraph>
          <Paragraph>Administrator informuje, że:</Paragraph>
          <Paragraph>
            <ul>
              <li>
                a. przeglądarki internetowe domyślnie akceptują instalowanie
                plików „cookies” w urządzeniu końcowym Użytkownika. Każdy
                Użytkownik Serwisu może dokonać w dowolnym czasie zmiany
                ustawień dotyczących plików „cookies” w używanej przez niego
                przeglądarce internetowej w taki sposób, aby przeglądarka
                automatycznie blokowała obsługę plików „cookies”, bądź
                informowała Użytkownika o ich każdorazowym zamieszczeniu w jego
                urządzeniu końcowym. Szczegółowe informacje o możliwości i
                sposobach obsługi plików „cookies” dostępne są w ustawieniach
                przeglądarki internetowej stosowanej przez Użytkownika Serwisu.
              </li>
              <li>
                b. ograniczenie stosowania plików „cookies” przez Użytkownika
                może ujemnie wpłynąć na poprawność i ciągłość świadczenia Usług
                w Serwisie.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            Pliki „cookies” zainstalowane w urządzeniu końcowym Użytkownika
            Serwisu mogą być wykorzystywane przez współpracujących z
            Administratorem reklamodawców lub partnerów biznesowych. Pliki
            „cookies” można uznać za dane osobowe jedynie w powiązaniu z innymi
            danymi identyfikującymi tożsamość, udostępnionymi Administratorowi
            przez Użytkownika w ramach korzystania z Serwisu. Dostęp do plików
            „cookies” przetwarzanych przez serwer Serwisu posiada wyłącznie
            Administrator. Jeśli Użytkownik nie zgadza się na zapisywanie i
            odbieranie informacji w plikach „cookies”, może zmienić zasady
            dotyczące plików „cookies” za pomocą ustawień swojej przeglądarki
            internetowej.
          </Paragraph>
        </section>
        <section>
          <Title level={4}>VII. INNE WAŻNE INFORMACJE</Title>
          <Paragraph>
            <Text className={'span'}>
              1. Ochrona bezpieczeństwa danych osobowych{' '}
            </Text>
            Administrator wprowadza odpowiednie środki mające na celu
            zapewnienie bezpieczeństwa danych osobowych Użytkownika. Bezpieczne
            korzystanie z Serwisu zapewniają stosowane systemy oraz procedury
            chroniące przed dostępem oraz ujawnieniem danych osobom
            niepożądanym. Ponadto stosowane przez Administratora systemy oraz
            procesy są regularnie monitorowane w celu wykrycia ewentualnych
            zagrożeń. Pozyskane przez Administratora dane osobowe przechowywane
            są w systemach komputerowych, do których dostęp jest ściśle
            ograniczony.
          </Paragraph>
          <Paragraph>
            <Text className={'span'}>2. Przechowywanie danych osobowych </Text>
            Okres przechowywania danych osobowych Użytkowników uzależniony jest
            od celów przetwarzania przez Administratora danych. Administrator
            przechowuje dane osobowe przez taki okres, jaki jest konieczny do
            osiągnięcia określonych celów, tj.: przez okres prowadzenia
            działalności gospodarczej przez Administratora. W każdym z
            powyższych przypadków, po upływie niezbędnego okresu przetwarzania,
            dane mogą być przetwarzane tylko w celu dochodzenia roszczeń na tle
            łączących strony relacji do czasu ostatecznego rozstrzygnięcia tych
            roszczeń na drodze prawnej.
          </Paragraph>
          <Paragraph>
            <Text className={'span'}>3. Zmiany Polityki prywatności </Text>W
            celu uaktualnienia informacji zawartych w niniejszej Polityce
            prywatności oraz jej zgodności z obowiązującymi przepisami prawa,
            niniejsza Polityka prywatności może ulec zmianie. W przypadku zmiany
            treści Polityki prywatności, zmieniona zostanie data jej
            aktualizacji wskazana na końcu jej tekstu. W celu zasięgnięcia
            informacji o sposobie ochrony danych osobowych, Administrator
            rekomenduje Użytkownikom regularne zapoznawanie się z
            postanowieniami Polityki Prywatności.
          </Paragraph>
          <Paragraph>
            <Text className={'span'}>4. Informacje kontaktowe </Text>W celu
            uzyskania jakichkolwiek informacji dotyczących niniejszej Polityki
            Prywatności, Użytkownik może skontaktować się z Administratorem
            danych osobowych:
            <Text className={'span'}>
              {' '}
              Jan Kowalski, Mińska 11, 20-000 Lublin,{' '}
            </Text>
            z wykorzystaniem następujących danych kontaktowych:{' '}
            <a href="mailto:lolekbolek@gmail.com" className="mail">
              lolekbolek@gmail.com
            </a>
            .
          </Paragraph>
          <Paragraph>
            Ponadto istnieje również możliwość kontaktu drogą pocztową pod
            adresem: <Text className={'span'}>Mińska 11, 20-000 Lublin.</Text>
          </Paragraph>
          <Paragraph>
            Ostatnia aktualizacja niniejszego dokumentu miała miejsce dnia
            23.05.2021.
          </Paragraph>
        </section>
      </div>
    </Layout>
  );
};
export default Chapter;
